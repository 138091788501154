import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, BrowserRouter } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'assets/scss/zest-admin.css'
import 'assets/fonts/simple-line-icons.css'
import 'assets/scss/WonE.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'assets/scss/multiselect.css'
import 'assets/scss/WoEOverride.scss'

import { ToastContainer } from 'react-toastify'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import allReducers from '../src/redux/reducer/index.jsx'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import Loader from '../src/components/common/Loader/Loader'
import Protected from '../src/components/common/Protected'
import FallbackLoading from '../src/components/common/FallbackLoading'
// import LoginPage from 'layouts/LoginPage.jsx';
// import WonELayout from 'layouts/WonE.jsx';
// import NotFound from '../src/components/common/NotFound';
import { REFRESH_TOKEN } from './Utility/graphQl/mutation.jsx'
import { Observable } from 'apollo-link'

const LoginPage = lazy(() => import('layouts/LoginPage'))
const WonELayout = lazy(() => import('layouts/WonE'))
const NotFound = lazy(() => import('../src/components/common/NotFound'))

const store = createStore(allReducers, applyMiddleware(thunk))

const hist = createBrowserHistory()
const httpLink = createHttpLink({
  // uri: constant.TEST_API_URL
  // uri: constant.QA_API_URL
  uri: process.env.REACT_APP_API_URL,
})

const authenticationFailed = () => {
  alert('Your token has expired. Please log in again.')
  localStorage.clear()
  window.history.go(0)
}

/**
 * Fix retriedResult.subscribe is not a function during foward(operation)
 * More info: https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
 */

const promiseToObservable = (args) =>
  new Observable((subscriber) => {
    args.then(
      (value) => {
        if (subscriber.closed) return
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => subscriber.error(err)
    )
    return subscriber
  })
const refreshToken = async () => {
  const rToken = localStorage.getItem('WonERefresh')

  if (rToken) {
    const result = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken: rToken,
      },
    })
    if (result.data) {
      localStorage.setItem('WonERefresh', result.data.refreshToken.refreshToken)
      localStorage.setItem('WonEToken', result.data.refreshToken.token)
    } else {
      authenticationFailed()
    }
  } else {
    authenticationFailed()
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('WonEToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  }
})

// Ref https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
const errorControl = onError(({ graphQLErrors, operation, forward }) => {
  const { response } = operation.getContext()

  if (response?.status === 401) {
    authenticationFailed()
  } else if (graphQLErrors) {
    for (const { message } of graphQLErrors) {
      switch (message) {
        case 'Error decoding signature':
        case 'User is disabled':
        case 'Invalid refresh token':
          authenticationFailed()
          break
        case 'Signature has expired':
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: localStorage.getItem('WonEToken'),
            },
          })
          // Retry the request, returning the new observable
          return promiseToObservable(refreshToken()).flatMap(() =>
            forward(operation)
          )

        default:
          return
      }
    }
  }
})

const client = new ApolloClient({
  // uri: 'http://18.134.13.114/graphql/',
  // url: 'https://48p1r2roz4.sse.codesandbox.io',
  link: errorControl.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ToastContainer />
      <Router history={hist} basename={process.env.REACT_APP_BASEDIR}>
        <BrowserRouter>
          <Suspense fallback={<FallbackLoading />}>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Protected path="/dashboard" component={WonELayout} />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Router>
      <Loader />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
