import { gql } from '@apollo/client'
//PRICING SYSTEM START
export const ADD_PRICING = gql`
  mutation CreatePricingSystem($input: PricingSystemCreateInput!) {
    createPricingSystem(input: $input) {
      pricingSystem {
        id
        classObj {
          id
          name
        }
        seniority {
          id
          level
        }
        classType {
          id
          name
        }
        duration {
          id
          duration
        }
        credit
      }
    }
  }
`

export const UPDATE_PRICING = gql`
  mutation UpdatePricingSystem($id: ID!, $input: PricingSystemUpdateInput!) {
    updatePricingSystem(id: $id, input: $input) {
      pricingSystem {
        id
        classObj {
          id
          name
        }
        seniority {
          id
          level
        }
        classType {
          id
          name
        }
        duration {
          id
          duration
        }
        credit
      }
    }
  }
`

export const DELETE_PRICING = gql`
  mutation DeletePricingSystem($id: ID!) {
    deletePricingSystem(id: $id) {
      pricingSystem {
        classObj {
          id
          name
        }
        seniority {
          id
          level
        }
        classType {
          id
          name
        }
        duration {
          id
          duration
        }
        credit
      }
    }
  }
`
//PRICING SYSTEM END

//CREDITS START
export const CREDIT_PACKAGE = gql`
  mutation CreatePackage($input: PackageCreateInput!) {
    createPackage(input: $input) {
      package {
        id
        credit
        discount
      }
    }
  }
`

export const UPDATE_PACKAGE = gql`
  mutation UpdatePackage($id: ID!, $input: PackageUpdateInput!) {
    updatePackage(id: $id, input: $input) {
      package {
        id
        credit
        discount
        price
      }
      ok
    }
  }
`

export const DELETE_PACKAGE = gql`
  mutation DeletePackage($id: ID!) {
    deletePackage(id: $id) {
      package {
        credit
        discount
        price
      }
      ok
    }
  }
`

export const CREATE_UPDATE_CREDIT_VALUE = gql`
  mutation CreateUpdateCreditValue($input: CreditValueInput!) {
    createUpdateCreditValue(input: $input) {
      creditValue {
        id
        value
      }
    }
  }
`
//CREDITS END

export const ADD_SERVICE = gql`
  mutation CreateService($input: ServiceCreateInput!) {
    createService(input: $input) {
      service {
        id
        name
        photo
        showOnApp
      }
    }
  }
`

export const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $input: ServiceUpdateInput!) {
    updateService(id: $id, input: $input) {
      service {
        id
        name
        photo
        showOnApp
      }
    }
  }
`

//COUPON START
export const ADD_COUPON = gql`
  mutation CreatePromoCode($input: PromoCodeCreateInput!) {
    createPromoCode(input: $input) {
      promoCode {
        id
        code
        discount
        classes {
          id
          name
        }
        usageLimit
        maxUsagePerCustomer
        expiryDate
      }
    }
  }
`
export const UPDATE_COUPON = gql`
  mutation UpdatePromoCode($id: ID!, $input: PromoCodeUpdateInput!) {
    updatePromoCode(id: $id, input: $input) {
      promoCode {
        id
        code
        discount
        classes {
          id
          name
        }
        usageLimit
        maxUsagePerCustomer
        expiryDate
      }
    }
  }
`
export const DELETE_COUPON = gql`
  mutation DeletePromoCode($id: ID!) {
    deletePromoCode(id: $id) {
      promoCode {
        code
        discount
        usageLimit
        maxUsagePerCustomer
        expiryDate
      }
    }
  }
`

//COUPON END

export const DELETE_PRACTITIONER = gql`
  mutation DeleteTeacherdetail($id: ID!) {
    deleteTeacherdetail(id: $id) {
      teacherDetail {
        phone
      }
    }
  }
`

export const ADD_PRACTITIONER = gql`
  mutation CreateTeacherdetail($input: TeacherDetailCreateInput!) {
    createTeacherdetail(input: $input) {
      teacherDetail {
        id
        phone
        landscapePhoto
        introVideoThumbnail
        introVideo
        userdetailObj {
          addresses {
            city
          }
          userObj {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`
export const UPDATE_PRACTITIONER = gql`
  mutation UpdateTeacherdetail($id: ID!, $input: TeacherDetailUpdateInput!) {
    updateTeacherdetail(id: $id, input: $input) {
      teacherDetail {
        id
        phone
        landscapePhoto
        introVideoThumbnail
        introVideo
        teachingAccreditations
        bio
        level {
          id
          level
        }
        location {
          id
          name
        }
        userdetailObj {
          photo
          addresses {
            addressChoice {
              id
              name
            }
            addressLine1
            addressLine2
            city
            country
            postCode
          }
          userObj {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteStudentdetail($id: ID!) {
    deleteStudentdetail(id: $id) {
      studentDetail {
        location {
          name
        }
        userdetailObj {
          role {
            name
          }
          userObj {
            firstName
            isActive
          }
        }
        availableCredits
      }
    }
  }
`

export const ADD_USER = gql`
  mutation CreateStudentdetail($input: StudentDetailCreateInput!) {
    createStudentdetail(input: $input) {
      studentDetail {
        id
        availableCredits
        location {
          id
          name
        }
        userdetailObj {
          userObj {
            id
            firstName
            lastName
            email
            username
          }
          role {
            id
            name
          }
        }
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateStudentdetail($id: ID!, $input: StudentDetailUpdateInput!) {
    updateStudentdetail(id: $id, input: $input) {
      studentDetail {
        id
        availableCredits
        userdetailObj {
          userObj {
            id
            username
          }
        }
      }
    }
  }
`

export const ADD_COMPANY = gql`
  mutation CreateCorporateCompany($input: CorporateCompanyCreateInput!) {
    createCorporateCompany(input: $input) {
      corporateCompany {
        id
        name
      }
    }
  }
`
export const ADD_ARTICLE = gql`
  mutation CreateArticle($input: ArticleCreateInput!) {
    createArticle(input: $input) {
      article {
        id
        author
        title
        subTitle
        content
        plans {
          id
          name
        }
        photo
        showOnCommunityPage
        pillar {
          name
        }
      }
    }
  }
`

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle($id: ID!, $input: ArticleUpdateInput!) {
    updateArticle(id: $id, input: $input) {
      article {
        id
        author
        title
        content
        plans {
          id
          name
        }
        photo
        showOnCommunityPage
        pillar {
          name
        }
      }
    }
  }
`

export const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      article {
        author
        title
      }
    }
  }
`

export const ADD_AVAILABILITY = gql`
  mutation createTeachercalendar($input: TeacherCalendarCreateInput!) {
    createTeachercalendar(input: $input) {
      teachercalendarDetail {
        id
        teacherdetailObj {
          id
        }
        availableDay
        fromTime
        toTime
        classType {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_AVAILABILITY = gql`
  mutation updateTeachercalendar(
    $id: ID!
    $input: TeacherCalendarUpdateInput!
  ) {
    updateTeachercalendar(id: $id, input: $input) {
      teacherCalendar {
        id
        teacherdetailObj {
          id
        }
        availableDay
        fromTime
        toTime
        classType {
          id
          name
        }
      }
    }
  }
`

export const DELETE_AVAILABILITY = gql`
  mutation deleteTeachercalendar($id: ID!) {
    deleteTeachercalendar(id: $id) {
      teacherCalendar {
        availableDay
        fromTime
        toTime
      }
    }
  }
`

export const ADD_DAYOFF = gql`
  mutation createTeachercalendardayoff(
    $input: TeacherCalendarDaysOffCreateInput!
  ) {
    createTeachercalendardayoff(input: $input) {
      teachercalendardayoff {
        teacherdetailObj {
          id
        }
        id
        daysOffFrom
        daysOffTo
      }
    }
  }
`

export const UPDATE_DAYOFF = gql`
  mutation updateTeachercalendardayoff(
    $id: ID!
    $input: TeacherCalendarDayOffUpdateInput!
  ) {
    updateTeachercalendardayoff(id: $id, input: $input) {
      teacherCalendarDayOff {
        id
        teacherdetailObj {
          id
        }
        daysOffFrom
        daysOffTo
      }
    }
  }
`

export const DELETE_DAYOFF = gql`
  mutation deleteTeachercalendardayoff($id: ID!) {
    deleteTeachercalendardayoff(id: $id) {
      teacherCalendarDayOff {
        daysOffFrom
        daysOffTo
      }
    }
  }
`

export const UPDATE_APPOINTMENT = gql`
  mutation UpdateClassSession($id: ID!, $input: ClassSessionUpdateInput!) {
    updateClassSession(id: $id, input: $input) {
      classSession {
        id
        classObj {
          id
          name
        }
        classType {
          id
          name
        }
        teacher {
          userdetailObj {
            userObj {
              firstName
              lastName
            }
          }
        }
        student {
          userdetailObj {
            userObj {
              firstName
              lastName
            }
          }
        }
        scheduledDate
        startTime
        endTime
        plan {
          name
        }
        address {
          id
        }
        invitationSet {
          guestUser {
            userdetailObj {
              userObj {
                firstName
                lastName
              }
            }
          }
          guestUserEmail
          hasAccepted
          hasJoined
        }
        credit
        promoCode {
          id
          code
        }
      }
    }
  }
`

export const ADD_CATEGOTY = gql`
  mutation CreateCategory($input: CategoryCreateInput!) {
    createCategory(input: $input) {
      category {
        id
        name
        description
      }
      ok
    }
  }
`
export const UPDATE_CATEGORY = gql`
  mutation UpdateCategoty($id: ID!, $input: CategoryUpdateInput!) {
    updateCategory(id: $id, input: $input) {
      category {
        id
        name
        description
      }
      ok
    }
  }
`
export const DELETE_CATEGORY = gql`
  mutation DeleteCategort($id: ID!) {
    deleteCategory(id: $id) {
      category {
        name
        description
      }
      ok
    }
  }
`

export const ADD_WELLBEING_PLAN = gql`
  mutation createPlan($input: PlanCreateInput!) {
    createPlan(input: $input) {
      plan {
        id
        name
        description
      }
      ok
    }
  }
`
export const UPDATE_WELLBEING_PLAN = gql`
  mutation UpdatePlan($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      plan {
        id
        name
        description
      }
      ok
    }
  }
`
export const DELETE_WELLBEING_PLAN = gql`
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id) {
      plan {
        name
      }
    }
  }
`

export const ADD_COMMUNITY_CLASS = gql`
  mutation CreateGroupSession($input: GroupSessionCreateInput!) {
    createGroupSession(input: $input) {
      groupSession {
        id
        groupSessionFor
        photo
        name
        description

        service {
          id
          name
        }
        classObj {
          id
          name
        }
        day
        startTime
        endTime
        classType {
          id
          name
        }
        preparationMaterial
        corporateCompany {
          id
          name
        }
        pillar {
          name
        }
      }
    }
  }
`

export const UPDATE_COMMUNITY_CLASS = gql`
  mutation UpdateGroupSession($id: ID!, $input: GroupSessionUpdateInput!) {
    updateGroupSession(id: $id, input: $input) {
      groupSession {
        id
        groupSessionFor
        photo
        name
        description
        service {
          id
          name
        }
        classObj {
          id
          name
        }
        classType {
          id
          name
        }
        repeatWeekly
        startTime
        endTime
        preparationMaterial
        corporateCompany {
          id
          name
        }
        pillar {
          name
        }
        calendarInviteTitle
        calendarInviteDescription
      }
    }
  }
`

export const DELETE_COMMUNITY_CLASS = gql`
  mutation DeleteGroupSession($id: ID!) {
    deleteGroupSession(id: $id) {
      groupSession {
        classObj {
          name
        }
        startTime
        endTime
      }
    }
  }
`

export const CANCEL_GROUP_SESSION = gql`
  mutation CancelGroupSession($id: ID!, $reasonForCancellation: String!) {
    cancelGroupSession(id: $id, reasonForCancellation: $reasonForCancellation) {
      groupSession {
        id
        status
      }
    }
  }
`

// export const UPDATE_GROUP_SESSION_TEACHER = gql`
// mutation UpdateGroupSessionTeacher($groupSessionID: ID!, $input: GroupSessionUpdateInput!) {
//   updateGroupSessionTeacher(groupSessionID: $groupSessionID, input: $input) {
//     groupSessionTeacherUpdatedList {
//       id
//       groupSession {
//         id
//       }
//       scheduledDate
//       teacher {
//         id
//         userdetailObj {
//           userObj {
//             firstName
//             lastName
//           }
//         }
//       }
//     }
//   }
// }
// `;

export const UPDATE_GROUP_SESSION_TEACHER = gql`
  mutation UpdateGroupSessionInstance(
    $groupSessionID: ID!
    $input: GroupSessionInstanceUpdateInput!
  ) {
    updateGroupSessionInstance(groupSessionID: $groupSessionID, input: $input) {
      groupSessionTeacherUpdatedList {
        id
        groupSession {
          id
        }
        scheduledDate
        teacher {
          id
          userdetailObj {
            userObj {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

export const ADD_CORPORATE_COMPANY = gql`
  mutation CreateCorporateCompany($input: CorporateCompanyCreateInput!) {
    createCorporateCompany(input: $input) {
      corporateCompany {
        id
        name
        billingEmail
        subscription
        individualCreditsPerEmployee
        companylocationSet {
          id
          location
        }
        woneCalendarType
      }
    }
  }
`

export const UPDATE_CORPORATE_COMPANY = gql`
  mutation UpdateCorporateCompany(
    $id: ID!
    $input: CorporateCompanyUpdateInput!
  ) {
    updateCorporateCompany(id: $id, input: $input) {
      corporateCompany {
        id
        name
        billingEmail
        subscription
        individualCreditsPerEmployee
        companylocationSet {
          id
          location
        }
        woneCalendarType
      }
    }
  }
`

export const DELETE_CORPORATE_COMPANY = gql`
  mutation DeleteCorporateCompany($id: ID!) {
    deleteCorporateCompany(id: $id) {
      corporateCompany {
        name
        billingEmail
        subscription
        individualCreditsPerEmployee
        companylocationSet {
          location
        }
        woneCalendarType
      }
    }
  }
`

export const CREATE_CORPORATE_COMPANY_ADMIN = gql`
  mutation CreateCorporateCompanyAdmin(
    $input: CorporateCompanyAdminCreateInput!
  ) {
    createCorporateCompanyAdmin(input: $input) {
      corporateCompanyAdmin {
        id
        admin {
          userdetailObj {
            photo
            userObj {
              firstName
              lastName
              email
            }
          }
        }
        location {
          id
          location
        }
      }
    }
  }
`

export const UPDATE_CORPORATE_COMPANY_ADMIN = gql`
  mutation UpdateCorporateCompanyAdmin(
    $id: ID!
    $input: CorporateCompanyAdminUpdateInput!
  ) {
    updateCorporateCompanyAdmin(id: $id, input: $input) {
      corporateCompanyAdmin {
        id
        company {
          id
          name
        }
        admin {
          userdetailObj {
            photo
            userObj {
              firstName
              lastName
              email
            }
          }
        }
        location {
          id
          location
        }
      }
    }
  }
`

export const DELETE_CORPORATE_COMPANY_ADMIN = gql`
  mutation DeleteCorporateCompanyAdmin($id: ID!) {
    deleteCorporateCompanyAdmin(id: $id) {
      ok
      deletedAdmin {
        userdetailObj {
          role {
            name
          }
          userObj {
            email
          }
        }
      }
    }
  }
`

export const CREATE_FEEDBACK = gql`
  mutation createTeacherFeedbackByStudent(
    $input: CreateTeacherFeedbackByStudentInput!
  ) {
    createTeacherFeedbackByStudent(input: $input) {
      teacherfeedbackbystudent {
        notes
      }
    }
  }
`

export const DELETE_CORPORATE_COMPANY_LOCATION = gql`
  mutation DeleteCorporateLocation($id: ID!) {
    deleteCorporateLocation(id: $id) {
      companyLocation {
        location
        company {
          name
        }
      }
    }
  }
`

export const CREATE_CLASS = gql`
  mutation CreateClass($input: ClassCreateInput!) {
    createClass(input: $input) {
      classModel {
        id
        name
        photo
        description
        preparationMaterials
        plans {
          id
          name
        }
        service {
          id
          name
        }
      }
    }
  }
`

export const UPDATE_CLASS = gql`
  mutation UpdateClass($id: ID!, $input: ClassUpdateInput!) {
    updateClass(id: $id, input: $input) {
      classModel {
        id
        name
        photo
        description
        preparationMaterials
        plans {
          id
          name
        }
        service {
          id
          name
        }
      }
    }
  }
`

export const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id) {
      service {
        name
        photo
      }
    }
  }
`

export const DELETE_CLASS = gql`
  mutation DeleteClass($id: ID!) {
    deleteClass(id: $id) {
      classModel {
        name
        photo
      }
    }
  }
`

export const ADD_BILLING = gql`
  mutation CreateTeacherBankDetail($input: TeacherBankDetailCreateInput!) {
    createTeacherbankdetail(input: $input) {
      teacherbankDetail {
        id
        accountHolderName
        accountCountry
        accountNo
        sortCode
        iban
        bicSwift
        billingAddress
        city
        postCode
        teacherdetailObj {
          id
        }
      }
    }
  }
`

export const UPDATE_BILLING = gql`
  mutation UpdateTeacherBankDetail(
    $id: ID!
    $input: TeacherBankDetailUpdateInput!
  ) {
    updateTeacherbankdetail(id: $id, input: $input) {
      teacherbankDetail {
        id
        accountHolderName
        accountCountry
        accountNo
        sortCode
        iban
        bicSwift
        billingAddress
        city
        postCode
        teacherdetailObj {
          id
        }
      }
    }
  }
`

export const ADD_EMPLOYEE = gql`
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      employee {
        id
        userdetailObj {
          userObj {
            firstName
            lastName
            email
          }
        }
      }
      sentMail
    }
  }
`

export const DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      employee {
        id
        company {
          name
        }
      }
    }
  }
`
export const ACTIVE_INACTIVE_EMPLOYEE = gql`
  mutation DeactivateEmployee($id: ID!) {
    deactivateEmployee(id: $id) {
      employee {
        id
        company {
          name
        }
        userdetailObj {
          userObj {
            email
            isActive
          }
        }
      }
      ok
    }
  }
`

export const ACTIVATE_DEACTIVATE_TEACHER = gql`
  mutation ActivateDeactivateTeacher($id: ID!) {
    activateDeactivateTeacher(id: $id) {
      ok
      teacherDetail {
        id
        userdetailObj {
          deactivatedDateTime
          userObj {
            isActive
          }
        }
      }
    }
  }
`
export const SEND_CALENDAR_INVITES = gql`
  mutation sendCalendarInvites($input: SendCalendarInvitesInput!) {
    sendCalendarInvites(input: $input) {
      ok
      totalCount
      sessionCalendarInviteObjectList {
        id
      }
    }
  }
`

export const CREATE_COMPANY_SIGNUP_LINK = gql`
  mutation CreateCompanySignUpLink($id: ID!, $timeZone: String!) {
    createCompanySignUpLink(id: $id, timeZone: $timeZone) {
      ok
      signUpLink
      signUpLinkExpiresOn
    }
  }
`
export const DELETE_COMPANY_SIGNUP_LINK = gql`
  mutation DeleteCompanySignUpLink($id: ID!) {
    deleteCompanySignUpLink(id: $id) {
      ok
      signUpLink
    }
  }
`

export const WONE_ADMIN_SEND_BULK_INVITES = gql`
  mutation WoneAdminSendBulkInvites($companyID: ID!) {
    woneAdminSendBulkInvites(companyId: $companyID) {
      ok
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`

export const REVOKE_TOKEN = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`


export const WONE_ADMIN_SCHEDULE_LEARNER_EMAIL_INVITES = gql`
  mutation WoneAdminScheduleLearnerEmailInvites($companyID:ID!) {
    woneAdminScheduleLearnerEmailInvites(companyId:$companyID){
      ok
      message
    }
  }
`