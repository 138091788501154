/** Test Url **/
let BASE_URL = 'http://18.130.180.166/graphql'
let DATE = new Date()
const { name, version } = require('../../package.json')

export default {
  TEST_API_URL: 'http://18.130.180.166/graphql/',
  TEST_FILE_UPLOAD_URL: 'http://18.130.180.166/',

  QA_API_URL: 'http://18.132.205.103/graphql/',
  QA_FILE_UPLOAD_URL: 'http://18.132.205.103/',

  PRODUCTION_URL: 'http://18.132.205.103/graphql',
  PRODUCTION_FILE_UPLOAD_URL: 'http://18.132.205.103/',

  maximumImageSise: 2001094,
  maximumVideoSize: 20010940,

  dimensions: {
    small10: 10,
    small15: 15,
    small12: 12,
    small20: 20,
    medium50: 50,
    medium40: 40,
  },
  colors: {
    blueCardColor: '#0078D4',
    purpleCardColor: '#B230B2',
    darkCyanCardColor: '#038387',
    greenCardColor: '#44BD32',
    blackCardColor: '#030F29',
    redCardColor: '#FF0000',
    balckCardColor40: '#030F2940',
    black: '#000000',
    grey: '#DDD',
    lightGray: '#F4F5F8',
    white: '#FFF',
    darkgrey: '#8c8c8c',
    primary: '#051175',
    primaryDark: '#030a49',
    lightWhite: '#F7F7F7F7',
    lightGray66: '#030F2966',
    alertBackColor: '#000000CC',
    mainScreenColor: 'linear-gradient(to right, #11CDEF , #3D74FC, #3C99FA)',
    btnColor: '#11CDEF',
    copyrightcolor: '#A9A9A9',
    blue: '#7764E4',
    orange: '#FB8266',
    pink: '#F53C56',
    orange1: '#FB6340',
    yellow: '#FEB969',
  },

  strings: {
    login: 'Login',
    empty_email: 'Please enter email id',
    shortPassword: 'Password should be 6 or more characters',
    signIn: 'Sing In',
    PASS_ERROR: 'PASS_ERROR',
    EMAIL_ERROR: 'EMAIL_ERROR',
    EMPTY_ERROR: 'EMPTY_ERROR',
    INVALID_EMAIL: 'INVALID_EMAIL',
    VALIDATE: 'VALIDATE',
    logOut: 'Logout',
    invalid_email: 'Please enter valid email address',
    emailpasserror: 'Please Enter email and password',
    copyrighttext: 'Copyright © 2011 by Rapidera. All Rights Reserved.',
    lbl_email: 'Email Address',
    lbl_password: 'Password',
    lbl_company: 'Enter Company Name',
    lbl_company_email: 'Email',
    lbl_company_phone: 'Phone No.',
    lbl_company_doj: 'Date Of Joining',
    submit: 'Submit',
    COMPANY_ADDED: 'Company addeed successfully',
    COMPANY_UPDATE: 'Company updated successfully',
    COMPANY_DELETE: 'Company deleted successfully',
    SERVER_ERROR: 'Someting wrong happen',
  },

  assets: {
    // sampleImage: { uri: 'https://cdn.cjr.org/wp-content/uploads/2019/07/AdobeStock_100000042-e1563305717660-686x371.jpeg' },
    // ic_calender: require('../assets/images/bg.jpeg'),
    // ic_meeting: require('../assets/images/people.jpg'),
    // ic_product: require('../assets/images/product.png'),
    // ic_userImage: require('../assets/images/user_image.png'),
    // intro_banner: require('../assets/images/intro_banner.png'),
    // rapideraLogo: require('../assets/images/rapideraLogo.png'),
    // addIcon: require('../assets/images/add.png'),
    // ic_loc: require('../assets/images/location.png'),
    // companyLogo: require('../assets/images/company.png'),
    profileDefault: require('../assets/img/profileDefault.jpg'),
  },

  /** Server Requests API */
  apis: {
    LOGIN_USER: BASE_URL + '/user/login',
  },
  /** Network Error Codes */
  networkErrors: {
    NETWORK_NOT_FOUND: 'Network request failed',
    JSON_PARSE_ERROR: 'JSON Parse error',
    PAGE_NOT_FOUND_404: '404',
    NET_NOT_CONNECTED: 'No Internet Connection',
  },

  /** Reducer Actions will be mentioned here */
  reducerActions: {
    IS_USER_LOGIN: 'IS_USER_LOGIN',
    CHANGE_TO_SEARCH: 'CHANGE_TO_SEARCH',
    SAVE_USER_DATA: 'SAVE_USER_DATA',
    SUPER_ADMIN_DASHBOARD: 'DASHBOARD',
    ALERT_MESSAGES: 'ALERT',
    LOADER: 'LOADER',
    SAVE_USER_TOKEN: 'SAVE_USER_TOKEN',
    SEATCH_TEXT: 'SEATCH_TEXT',
    TEACHER_TAB: 'TEACHER_TAB',
  },

  global: {
    token: '',
  },
  URL: [],
  pagination: {
    itemsCountPerPage: 9,
    pageRangeDisplayed: 5,
  },

  deleteConfiguration: {
    title: 'Delete',
    message: 'Are you sure,you want to delete?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  companyDeleteConfiguration: {
    title: 'Delete',
    message: 'Are you sure you want to delete the sign-up link?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  companyRefreshConfiguration: {
    title: 'Refresh',
    message: 'Are you sure you want to refresh the sign-up link?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  cancelConfiguration: {
    title: 'Cancel',
    message: 'Are you sure,you want to cancel?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  activeConfiguration: {
    title: 'Inactive',
    message: 'Are you sure, you want to mark active?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  inactiveConfiguration: {
    title: 'Inactive',
    message: 'Are you sure, you want to mark inactive?',
    confirmText: 'Yes',
    confirmColor: 'primary',
    cancelColor: 'secondary',
    cancelText: 'No',
  },
  currentDate:
    DATE.getFullYear() +
    '-' +
    ('0' + (DATE.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + DATE.getDate()).slice(-2),

  APP_NAME: name,
  APP_VERSION: version,
  APP_PLATFORM: 'WEB',
}
